// constants
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";

// log
import { fetchData } from "../data/dataActions";

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const wconnect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const abiResponse = await fetch("/config/abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const abi = await abiResponse.json();
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const CONFIG = await configResponse.json();
   // const { ethereum } = window;
   
   const walet = new WalletConnectProvider({
      infuraId: undefined,
      rpc: {
        137: "https://rpc-mainnet.maticvigil.com/v1/d1fb9b7cdf0a54ce54a4f0c94d2ade1d145bdd73",
        80001:"https://rpc-mumbai.maticvigil.com/v1/d1fb9b7cdf0a54ce54a4f0c94d2ade1d145bdd73"
      },  chainId: 137
    });
      //await walet.enable();
      try {
      
          await walet.enable();
          if(!walet.chainId == CONFIG.NETWORK.ID){
            dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
            // Close provider session
              await walet.disconnect()
          }

        } catch (error) {
        console.log(error);
        }
  
    if (walet.connected) {
      Web3EthContract.setProvider(walet);

     // Enable web3 and get the initialized web3 instance from Web3.js
    
     let web3 = new Web3(walet);
     console.log('web3', web3);
      try {
        const accounts = await web3.eth.getAccounts();
        const networkId = walet.chainId;
        if (networkId == CONFIG.NETWORK.ID) {
          const SmartContractObj = new Web3EthContract(
            abi,
            CONFIG.CONTRACT_ADDRESS
          );
          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: SmartContractObj,
              web3: web3,
            })
          );
          // Add listeners start
          walet.on("accountsChanged", (accounts) => {
            dispatch(updateAccount(accounts[0]));
          });
          walet.on("chainChanged", (chainId) => {
            window.location.reload();
          });
          walet.on("disconnect", (code, reason) => {
            window.location.reload();
            console.log(code, reason);
          });
          // Add listeners end
        } else {
          dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
        }
      } catch (err) {
        dispatch(connectFailed("connection failed."));
      }
    } else {
      dispatch(connectFailed("wallet not connected."));
    }
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};
